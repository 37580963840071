<template>
  <div class="ccc home">
    <TopHead :name="basicInfo.name"></TopHead>
    <logoNav></logoNav>
    <!--页面主体-->
    <section class="main">
      <div class="container">
        <div class="left">
          <div class="box info">
            <div class="boxname">
              <h1><i class="icon-info"></i>分销商信息</h1>
            </div>
            <div class="content">
              <div class="name">{{basicInfo.name}}<span>{{basicInfo.typeName}}</span><!--<span>一级代理</span>--></div>
            </div>
            <div class="detail">
              <div class="money">
                账户余额<span>￥<em>{{basicInfo.balance ? basicInfo.balance/100 : 0}}</em></span><a v-if="basicInfo.balance" href="/#/cashing">提现</a>
              </div>
              <div class="quota">
                授信额度<span>￥<i>{{basicInfo.creditAmount ? basicInfo.creditAmount/100 : 0}}</i></span>
              </div>
              <div class="leftQuota">
                可用授信额度<span>￥<i>{{basicInfo.creditBalance ? basicInfo.creditBalance/100 : 0}}</i></span>
              </div>
            </div>
          </div>
          <div class="box order">
            <div class="boxname">
              <a href="/#/order" title="全部订单">全部订单</a>
              <h1><i class="icon-order"></i>最近订单</h1>
            </div>
            <div class="content">
              <table>
                <thead>
                <tr class="gray">
                  <th>订单编号</th>
                  <th>订单状态</th>
                  <th>总票数</th>
                  <th>总金额</th>
                  <th>预订消费日期</th>
                  <th>预订人姓名</th>
                  <th>预订人电话</th>
                  <th>支付方式</th>
                  <th>支付单号</th>
                  <th>下单时间</th>
                  <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!listOrder.length"><td colspan="11">
                  <div class="loading">
                    <!--<div class="loading-animate">
                        <span></span><span></span><span></span><span></span><span></span>
                    </div>-->
                    <div>暂无数据</div>
                  </div>
                </td></tr>
                <template v-else>

                  <tr v-for="(item,index) in listOrder" :key="index" :class="index%2 == 1 ? 'gray' : ''">
                    <td><a href="javascript:;" @click="goDetail(item)">{{item.orderNumber}}</a></td>
                    <td>{{item.orderStatus ? staArr[item.orderStatus].name : '--'}}</td>
                    <td>{{item.totalPurchaseNumber}}</td>
                    <td>{{item.totalSellPrice ? item.totalSellPrice/100 : 0}}</td>
                    <td>{{item.bookUseDate ? item.bookUseDate.split(' ')[0] : ''}}</td>
                    <td>{{item.reservationPersonName}}</td>
                    <td>{{item.reservationPersonTelephone}}</td>
                    <td>{{getPayType(item.payTypeId )}}</td>
                    <td>{{item.payNumber}}</td>
                    <td class="text_over">{{item.creatorTime}}</td>
                    <td>

                      <div class="d_f ali_c j_c">
                        <div class="right_btn cur_p" @click="orderRefundRow(item)" v-if="item.orderStatus == 5">
                          <img src="../assets/images/o_tk.png" alt="">
                          <span>退款</span>
                        </div>
                        <div class="right_btn cur_p ml_5" @click="payRow(item)" v-if="item.orderStatus<2">
                          <img src="../assets/images/tr_zhifu.png" alt="">
                          <span>支付</span>
                        </div>
                        <div class="right_btn big cur_p ml_5" @click="refundDetail(item)" v-if="item.orderStatus == 5">
                          <img src="../assets/images/o_tk.png" alt="">
                          <span>退款详情</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="box ticket">
            <div class="boxname">
              <h1><i class="icon-ticket"></i>票务中心</h1>
            </div>
            <div class="content">
              <a href="/#/ticket" title="我要订票" class="t_a">我要订票</a>
            </div>
          </div>
          <div class="box notice">
            <div class="boxname">
              <a href="/#/message">查看全部</a>
              <h1><i class="icon-notice"></i>通知公告</h1>
            </div>
            <div class="content">
              <ul>
                <!--最大11条-->
                <li v-for="(item,index) in noticeArr" :key="index"><a href="javascript:;" @click="goMessage(item,1)" rel="noopener noreferrer"><span>{{item.title}}</span><span>{{item.writtenTime ? item.writtenTime.split(' ')[0] : ''}}</span></a></li>
              </ul>
            </div>
          </div>
          <div class="box knowledge">
            <div class="boxname">
              <a href="/#/message">查看全部</a>
              <h1><i class="icon-knowledge"></i>知识库</h1>
            </div>
            <div class="content">
              <ul>
                <a v-for="(item,index) in zsArr" :key="index" @click="goMessage(item)" target="_blank" rel="noopener noreferrer"><img v-if="item.fileDataList.length" :src="$global.hostUrl+'/uploadFile/downloadFile/'+item.fileDataList[0].fileId" :alt="item.title" /> </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: 'Home',
    data() {
      return{
        basicInfo:{},
        typeArr:[],
        pageInfo:{
          "pageIndex":1,
          "pageSize":11,
          "data":{'title':'','newsClassificationId':'3798776'}
        },
        staArr:[
          {value:0,name:'未支付'},
          {value:1,name:'待支付'},
          {value:2,name:'已取消'},
          {value:3,name:'订单关闭'},
          {value:4,name:'出票失败'},
          {value:5,name:'出票成功'},
        ],
        noticeArr:[],
        zsArr:[],
        pageInfoOrder:{
          pageIndex:1,
          pageSize:10,
          data:{
            orderNumber:'',
            orderStatus:'',
            startDate:'',
            endDate:'',
            createStartDate:'',
            createEndDate:'',
            reservationPersonName:'',
            reservationPersonTelephone:'',
            payTypeId:'',
          }
        },
        listOrder:[],
        payArr:[],
      }
    },
    created(){
      this.queryDisPayType();
      this.getEnumsByCode();
      this.getOrder();
      this.getNotice();
      this.getNotice(1);
    },
    methods:{
      //跳转退款
      orderRefundRow(row){
        this.$router.push({path:'/orderRefund',query:{orderId:row.orderId,orderNumber:row.orderNumber}});
      },
      //跳转退款详情
      refundDetail(row){
        this.$router.push({path:'/refundDetail',query:{orderId:row.orderId,orderNumber:row.orderNumber}});
      },
      //跳转支付
      payRow(row){
        this.$router.push({path:'/pay',query:{id:row.orderId,num:row.orderNumber}});
      },
      //支付方式
      queryDisPayType(){
        this.$api.queryDisPayType().then(res => {
          this.payArr = res.data;
        })
      },
      getPayType(id){
        let str ='';
        this.payArr.map(item => {
          if(id == item.value){
            str = item.name
          }
        });
        return str
      },
      //跳转详情
      goDetail(row){
        this.$router.push('/orderDetails?orderId='+row.orderId+'&num='+row.orderNumber)
      },
      getOrder(){

        this.$api.queryOrderList(this.pageInfoOrder).then(res => {
          this.listOrder = res.data.rows;
        })
      },
      goMessage(row,num){
        let routeUrl = this.$router.resolve({
          path:'/messageDetails',
          query:{
            newsId:row.newsId,
            type: num ? 'message' : '',
          }
        });
        window.open(routeUrl.href, '_blank');
      },
      //获取通知公告
      getNotice(num){
        if(num){
          this.pageInfo.data.newsClassificationId = '3798782';
        }else{
          this.pageInfo.data.newsClassificationId = '3798776';
        }
        this.$api.queryPage(this.pageInfo).then(res => {
          if(num){
            this.zsArr = res.data.rows;
          }else{
            this.noticeArr = res.data.rows;
          }
        })
      },
      //获取枚举
      getEnumsByCode(){
        this.$api.getEnumsByCode('business_type').then(res => {
          this.typeArr = res.data;
          this.getBusinessInfo();
        })
      },
      getBusinessInfo(){
        this.$api.getBusinessInfo().then(res => {
          this.basicInfo = res.data;
          sessionStorage.setItem('businessInfoId',this.basicInfo.businessInfoId);
          sessionStorage.setItem('name',this.basicInfo.name);
          this.typeArr.map(item => {
            if(item.value == res.data.typeId){
              this.basicInfo.typeName = item.name
            }
          })
        })
      }
    }
  }
</script>
<style scoped>
  .main {
    margin-top: 20px;
  }
  .main .left{
    width: calc(100% - 400px);
    display: inline-block;
    vertical-align: top;
  }
  .main .right {
    width: 390px;
    margin-left: 10px;
    display: inline-block;
  }
  .main .box{
    width: 100%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px #eee;
    margin-bottom: 10px;
    margin-top: 0;
    box-sizing: border-box;
  }
  .main .box .boxname{
    height: 56px;
    padding: 0 20px;
    border-bottom: none;
  }
  .main .box .boxname a{
    font-size: 14px;
    color: #999;
    float: right;
    margin-top: 20px;
  }
  .main .box .boxname a:hover{
    color: #333;
    text-decoration: underline;
  }
  .main .container .info,.main .container .ticket{
    height: 164px;
  }
  .main .container .order{
    height: 610px;
  }
  .main .container .notice{
    height: 450px;
  }
  .main .container .knowledge{
    height: 150px;
  }
  .main .box .boxname h1{
    font-size: 18px;
    text-align: left;
    line-height: 56px;
  }
  .main .box .boxname i{
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: text-bottom;
  }
  .main .box .boxname i.icon-info{
    background: url(../assets/images/icon.png) no-repeat -34px -169px;
  }
  .main .box .boxname i.icon-ticket{
    background: url(../assets/images/icon.png) no-repeat -102px -169px;
  }
  .main .box .boxname i.icon-order{
    background: url(../assets/images/icon.png) no-repeat -68px -169px;
  }
  .main .box .boxname i.icon-notice{
    background: url(../assets/images/icon.png) no-repeat -136px -169px;
  }
  .main .box .boxname i.icon-knowledge{
    background: url(../assets/images/icon.png) no-repeat -170px -169px;
  }
  .main .box .content{
    margin: 0 10px;
    padding: 0 10px;
  }
  .main .info{
    text-align: left;
  }
  .main .info li{
    font-size: 16px;
    line-height: 30px;
  }
  .main .info li span{
    color: #888;
    margin-right: 20px;
  }
  .main .info a{
    color: #fec714;
    font-style: italic;
    margin-left: 20px;
    text-decoration: underline;
    display: inline-block;
  }
  .main .info a:hover{
    transform: scale(1.1);
    text-decoration: none;
  }
  .main .info .content .name{
    font-size: 18px;
  }
  .main .info .content .name span{
    margin-left: 10px;
    border: 1px solid #1cd0bd;
    font-size: 12px;
    color: #fff;
    background: #1cd0bd;
    border-radius: 3px;
    padding: 3px 5px;
  }
  .main .info .content .name span:nth-of-type(2n) {
    color: #1cd0bd;
    background: #fff;
  }
  .main .info .detail{
    display: flex;
    text-align: center;
    padding-top: 25px;
  }
  .main .info .detail .money,
  .main .info .detail .quota,
  .main .info .detail .leftQuota{
    flex: auto;
    border-left: 1px solid #b7b7b7;
    font-size: 16px;
    height: 27px;
    line-height: 27px;
  }
  .main .info .detail .money{
    border: none;
  }
  .main .info .detail span{
    margin-left: 20px;
  }
  .main .info .detail em{
    font-size: 30px;
  }
  .main .info .detail i{
    font-size: 20px;
    font-style: normal;
  }
  .main .ticket a{
    font-size: 24px;
    background: #fec714;
    display: block;
    height: 70px;
    line-height: 70px;
    border-radius: 10px;
    margin-top: 14px;
  }
  .main .ticket a:hover{
    font-size: 30px;
    color: #fff;
  }
  .main .order table{
    width: 100%;
  }
  .main .order table th{
    font-weight: bold;
  }
  .main .order th,.main .order td{
    font-size: 14px;
    line-height: 20px;
    padding: 15px 5px;
    word-break: keep-all;
  }
  .main .order td a{
    color: #1cd0bd;
    text-decoration: underline;
  }
  .main .order td a:hover{
    font-style: italic;
  }
  .main .order a.btn{
    color: #333;
    text-decoration: none;
    border: 1px solid;
    width: 68px;
    height: 30px;
    display: inline-block;
    white-space: nowrap;
    line-height: 28px;
    font-weight: bold;
  }
  .main .order a.btn i{
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    margin: -5px 5px 0 0;
  }
  .main .order a.btn i.icon-pay{
    background: url(../assets/images/icon.png) no-repeat -360px 0;
  }
  .main .order a:hover.btn i.icon-pay{
    background-position: -360px -26px;
  }
  .main .order a.btn i.icon-refund{
    background: url(../assets/images/icon.png) no-repeat -386px 0;
  }
  .main .order a:hover.btn i.icon-refund{
    background-position: -386px -26px;
  }
  .main .order a:hover.btn{
    font-style: normal;
    color: #1cd0bd;
  }
  .main .order .gray{
    background: #f4f4f4;
  }
  .main .order .content{
    max-height: 550px;
    overflow-y: auto;
    width: calc(100% - 20px);
    box-sizing: border-box;
  }
  .main .notice .content{
    padding-top: 10px;
    border-top: 1px solid #ccc;
  }
  .main .notice li{
    font-size: 14px;
    line-height: 33px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    list-style: inside;
    transition: all 0.25s ease 0.1s;
  }
  .main .notice li:hover,.main .notice li a:hover{
    color: #1cd0bd;
  }
  .main .notice li span{
    display: inline-block;
  }
  .main .notice li span:first-of-type{
    width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  .main .notice li span:last-of-type{
    width: 80px;
    text-align: right;
  }
  .main .container .knowledge ul{
    text-align: left;
  }
  .main .container .knowledge ul a{
    margin: 10px;
    display: inline-block;
  }
  .main .container .knowledge ul img{
    width: 65px;
    height: 65px;
  }
</style>
